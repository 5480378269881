import { IonCol, IonRow } from '@ionic/react';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import { regex } from '../../../regex/regex';
import { validatePhoneNumber } from '../../Registration/config';

const CallMeUp: React.FC = () => {
  const { t } = useTranslation();
  const methods = useForm<{ phone: string }>();
  const [openRequestModal, setOpenRequestModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const isLoading = useAppSelector(state => state.loading.isLoading.joinProjectForm);
  const isRequestCallbackLoading = useAppSelector(state => state.loading.isLoading.requestCallback);
  const user = useAppSelector(state => state.authentication.user);

  const handleDismiss = () => {
    setOpenRequestModal(false);
    setSuccessMessage(undefined);
    methods.reset({ phone: '' });
  };

  const requestCallback = (data: { phone: string }) => {
    store.dispatch(setIsLoading({ name: 'requestCallback', value: true }));
    networking.post('/api/v1/clients/request_call_back', {
      phone_number: data.phone
    })
      .then(() => {
        setSuccessMessage(t(`We will call you on phonenumber {phone_number} within 30 seconds to help you get started with BigUp.`, {
          phone_number: data.phone
        }));
      })
      .catch(() => {
        toasters.createToast({
          message: t('Failed to request a call-up. Please try again later.')
        }, 'error');
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'requestCallback', value: false }));
      });
  };

  const onCallMeUpClick = () => {
    setOpenRequestModal(true);
    methods.setValue('phone', user?.phone_number ?? '');
  };

  return (
    <>
      <IonRow className='ion-justify-content-center ion-padding-horizontal ion-padding-bottom'>
        <IonCol size='10'>
          <BigUp.Buttons.Primary
            title={t('Call me up')}
            expand='block'
            onClick={onCallMeUpClick}
          />
        </IonCol>
      </IonRow>

      <FormProvider {...methods}>
        <BigUp.Modal.InputModal
          modal={{
            ...successMessage && { successMessage },
            isOpen: openRequestModal,
            onDismiss: handleDismiss,
            title: t('Request a call-up'),
            description: successMessage || t('Enter your phonenumber and we will call you within 30 seconds to help you get started with BigUp.')
          }}
          input={{
            disabled: isRequestCallbackLoading,
            showErrorBadge: true,
            name: 'phone',
            type: 'tel',
            placeholder: t('Enter phone number'),
            register: 'phone',
            validation: {
              required: t('Phone number is required'),
              validate: (value) => validatePhoneNumber(value) || t('Invalid phone number')
            }
          }}
          button={{
            title: t('Request call-up'),
            disabled: isLoading || isRequestCallbackLoading || !methods.formState.isValid,
            loading: isLoading || isRequestCallbackLoading,
            type: 'submit',
            onClick: () => { }
          }}
          textAlignment='center'
          onSubmit={methods.handleSubmit(requestCallback)}
        />
      </FormProvider>
    </>
  );
};

export default CallMeUp;
