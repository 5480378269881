import type { InputCustomEvent } from '@ionic/react';
import { IonInput } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import BigUp from '..';
import type { InputProps } from './Input.interface';
import formatNumber from '../../../tools/formatNumber';

export const InputSkeleton: React.FC<React.PropsWithChildren<InputProps<E2U.V1.Models.User | string>>> = (props) => {
  const label = props.label || undefined;
  const { children, onIonChange: parentIonChangeCallback, showErrorBadge = true, showWarningBadge = true, ...rest } = props;

  const methods = useFormContext();
  const fieldValue = methods.watch(props.register);

  const errors = useMemo(() => {
    return methods.formState.errors;
  }, [methods.formState.errors, fieldValue]);

  const error = useMemo(() => {
    return props.forceError || methods.formState.errors[props.register]?.message;
  }, [methods.formState.errors, props.forceError, fieldValue]);

  const trimValue = (value: string) => {
    return value?.toString().replace(/ /g, '').replace(/,/g, '.').trim();
  };

  const isValueNumeric = (value: string | number) => {
    return !isNaN(parseFloat(trimValue(value.toString())));
  };

  const shouldBeFormatterWithDefaultFormatter = (value: string | number) => {
    const formatter = props?.formatter;

    const isNumericType = (
      (typeof props?.type !== 'undefined' && props?.type === 'number') ||
      (typeof props?.inputMode !== 'undefined' && ['numeric', 'decimal'].includes(props?.inputMode?.toLowerCase()))
    );

    return (
      (typeof formatter === 'undefined' || formatter === null || formatter === true) &&
      (isValueNumeric(value) && isNumericType)
    );
  };

  const getFormattedValue = (value: string | number) => {
    const formatter = props?.formatter;

    const stringVal = value.toString();
    const valueTrimmed = trimValue(stringVal);
    const isNumeric = isValueNumeric(value || '');

    if (shouldBeFormatterWithDefaultFormatter(value)) {
      // Default formatter will run ONLY IF field is of numeric type & value is numeric
      if (valueTrimmed.indexOf('.') === -1 || valueTrimmed.substring(valueTrimmed.indexOf('.') + 1).length >= 2) {
        value = formatNumber(parseFloat(valueTrimmed), true);
      }
    } else if (typeof formatter === 'function') {
      value = formatter(value);
    }

    return { value, isNumeric, valueTrimmed };
  };

  const fieldDisplayValue = useMemo(() => {
    const { value } = getFormattedValue(fieldValue ?? '');
    return value;
  }, [fieldValue]);

  const handleOnChange = (e: InputCustomEvent) => {
    const { value } = getFormattedValue(e.target.value ?? '');

    methods.setValue(props.register, value);

    if (parentIonChangeCallback) {
      parentIonChangeCallback(e);
    }

    methods.trigger(props.register);
  };

  const setServerErrors = () => {
    methods.setError(props.register, {
      type: 'server',
      message: props.serverError?.toString(),
    }, {
      shouldFocus: true,
    });
  };

  useEffect(() => {
    if (props.serverError) {
      setServerErrors();
    }
  }, [props.serverError]);

  return (
    <>
      <IonInput
        {...rest}
        {...rest.register && methods.register(rest.register, {
          ...rest.validation,
          setValueAs: (value) => {
            if (shouldBeFormatterWithDefaultFormatter(value ?? '')) {
              value = parseFloat(trimValue(value.toString()));
            }

            // @todo: props.formatter needs to support second parameter (which will indicate that we want value which should be sent to backend here)
            // @todo: alternatively, we can add another prop for that

            return value;
          }
        })}
        color={error ? 'danger' : 'medium'}
        label={label}
        labelPlacement={rest.labelPlacement || 'stacked'}
        className={rest.className || 'ion-margin-left'}
        autoCapitalize='sentences'
        clearInput={false}
        type={rest.type}
        onIonInput={handleOnChange}
        value={fieldDisplayValue}
      >
        {children}
      </IonInput>
      {showErrorBadge && errors && errors[rest.register] && (
        <BigUp.Label.Regular color='danger' className='ion-no-margin'
          label={errors[rest.register]?.message as string} />
      )}
      {showErrorBadge && rest.forceError && (
        <BigUp.Label.Regular color='danger' className='ion-no-margin' label={rest.forceError as string} />
      )}
      {showWarningBadge && rest.forceWarning && (
        <BigUp.Label.Regular color='warning' className='ion-no-margin' label={rest.forceWarning as string} />
      )}
    </>
  );
};

const Input: React.FC<InputProps<E2U.V1.Models.User | string>> = (props) => {
  const inputStyles = useMemo(() => {
    return {
      '--color': 'var(--ion-color-dark)',
      ...(props.lines !== false && !props.helperText && {
        borderBottom: '1px solid var(--ion-color-medium)',
      }),
    };
  }, [props.lines, props.helperText]);

  return <InputSkeleton style={inputStyles} {...props} />;
};

export default Input;
