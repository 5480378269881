import { IonCol, IonGrid, IonIcon, IonLabel, IonRow, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';

import styles from './DocumentForm.module.scss';
import { networking } from '../../../api/networking';
import attachmentsSVG from '../../../components/icons/attachments.svg';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../components/Toasts/Toasts';
import { BigUp } from '../../../components/UI';
import DragAndDrop from '../../../components/UI/DragAnddrop/DragAndDrop';
import MultiSelector from '../../../components/UI/MultiSelector';
import type { FilePreviewProps } from '../../../components/UI/Preview/types';
import TextareaOutlined from '../../../components/UI/Textareas/TextareaOutlined';
import { useAppSelector } from '../../../hooks';
import useCameraUpload from '../../../hooks/useCameraUpload';
import useFilePreviews from '../../../hooks/useFilePreview';
import { useFileReader } from '../../../hooks/useFileReader';
import useFileUpload from '../../../hooks/useFileUpload';
import i18n from '../../../i18n';
import { setSelectedFile, setShouldRefetchDocuments } from '../../../reducers/file';
import { setIsLoading } from '../../../reducers/loading';
import { getDocumentTypeColor } from '../Category/CategoryTitleColumn';

interface UploadDocumentFormProps {
  handleDocumentSaved?: (document: E2U.V1.Models.Document) => void
  handleCloseModal?: () => void;
}

const DocumentForm: React.FC<UploadDocumentFormProps> = (props: UploadDocumentFormProps) => {
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[] }>({});
  const [fileListViewToggle, setFileListViewToggle] = useState<boolean>(false);
  const store = useStore();
  const router = useIonRouter();
  const { t } = useTranslation();
  const document = useAppSelector((state) => state.file.selectedFile);
  const isLoadingDocument: boolean = useAppSelector((state) => state.loading.isLoading.document);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const { readFiles, result, thumbnails } = useFileReader();
  const { fileUrls, loadingPreview } = useFilePreviews(document?.files, [document?.files]);
  const cameraProps = useCameraUpload();
  const fileProps = useFileUpload();
  const files = fileProps.getUploadedFiles() ?? [];
  const photos = cameraProps.getUploadedPhotos() ?? [];
  const selectedProjectId = selectedProject?.id;
  const selectedDocumentCategory = useAppSelector(state => state.document.selectedCategory);
  const categoryTags = useMemo(() => selectedDocumentCategory?.category_tags ?? [], [selectedDocumentCategory]);

  const fileUploadLabel = useMemo(() => {
    return isDesktop ? t('Drop your files here to upload') : t('Click here to add files to upload');
  }, [isDesktop]);

  const methods = useForm<E2U.V1.Models.Document & {
    local_tags: string[],
    local_activity_codes: string[],
    local_document_types: string[],
  }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: undefined,
      description: undefined,
      local_tags: [],
      local_activity_codes: [],
    }
  });
  const localTags = methods.watch('local_tags');
  const localActivityCodes = methods.watch('local_activity_codes');
  const localDocumentTypes = methods.watch('local_document_types');

  const submitButtonDisabled = useMemo(() => {
    return (methods.formState.isSubmitting || isLoadingDocument) ||
      (!methods.formState.isValid || Object.keys(validationErrors).length > 0) ||
      (photos.length === 0 && files.length === 0);
  }, [methods.formState.isSubmitting, isLoadingDocument, methods.formState.isValid, validationErrors, photos, files]);

  const uploadButtonDisabled = useMemo(() => {
    return (methods.formState.isSubmitting || isLoadingDocument);
  }, [methods.formState.isSubmitting, isLoadingDocument]);

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({ name: 'uploadingFile', value: true }));
    if (selectedProject && selectedProject.id) {
      data.project_id = selectedProject.id;
    }
    if (data.name === '') {
      delete data.name;
    }
    if (data.description === '') {
      delete data.description;
    }
    const selectedActivityCodeIds: string[] = data.local_activity_codes ?? [];
    const selectedTagIds: string[] = [...(data.local_tags ?? []), ...(data.local_document_types ?? [])];
    delete data.local_activity_codes;
    delete data.local_tags;
    delete data.local_document_types;
    const request = (data.id)
      ? networking.put(`/api/v1/documents/${data.id}`, data)
      : networking.post(`api/v1/projects/${selectedProject.id}/documents`, data);
    return toasters.promise(new Promise((resolve, reject) => {
      request.then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Document | any>) => {
          Promise.allSettled([
            fileProps.uploadSelectedFiles('/api/v1/documents', response.data?.data?.id ?? response.data?.data),
            cameraProps.uploadSelectedPhotos('/api/v1/documents', response.data?.data?.id ?? response.data?.data),
          ])
            .then(() => {
              const documentId = response.data?.data?.id ?? response.data?.data;

              // Activity codes
              const existingActivityCodes = document?.activity_codes ?? [];
              const activityCodesToRemove = existingActivityCodes.filter(
                (activityCode: E2U.V1.Models.ActivityCode) => !selectedActivityCodeIds.some((sac) => sac === activityCode.id)
              ).map((activityCode: E2U.V1.Models.ActivityCode) => networking.delete(`/api/v1/documents/${documentId}/activity_codes/${activityCode.id}`));
              const activityCodesToAdd = selectedActivityCodeIds.filter(
                (activityCodeId: string) => !existingActivityCodes.some((eac) => eac.id === activityCodeId)
              ).map((activityCodeId: string) => networking.post(`/api/v1/documents/${documentId}/activity_codes/${activityCodeId}`));

              // Tags
              const existingTagSelection = document?.related_tags ?? [];
              const tagsToRemove = existingTagSelection.filter(
                (tag: E2U.V1.Models.Tag) => !selectedTagIds.some((tagId) => tagId === tag.id)
              ).map((tag: E2U.V1.Models.Tag) => networking.delete(`/api/v1/documents/${documentId}/related_tags/${tag.id}`));

              const tagsToAdd = selectedTagIds.filter(
                (tagId: string) => !existingTagSelection.some(
                  (est) => est.id === tagId
                )
              ).map((tagId: string) => networking.post(`/api/v1/documents/${documentId}/related_tags/${tagId}`));

              Promise.allSettled([
                ...tagsToRemove,
                ...tagsToAdd,
                ...activityCodesToRemove,
                ...activityCodesToAdd
              ]).then(() => {
                resolve(response);
              }).catch((error) => {
                reject(error);
              });
            })
            .catch((error) => {
              reject(error);
            });
        }
      ).catch(
        (error: E2U.V1.Response.Error<E2U.V1.Models.Document>) => {
          if (error &&
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message === 'Validation failed'
          ) {
            setValidationErrors(error.response.data.data);
          } else {
            Sentry.captureException(error);
          }
          reject(error);
        }
      );
    }), {
      pending: (document && document.id) ? t('Saving document') : t('Creating document'),
      success: (document && document.id) ? t('Document saved') : t('Document created'),
      error: (document && document.id) ? t('Could not save document') : t('Could not create document')
    })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Document | string>) => {
        if (props.handleDocumentSaved) {
          store.dispatch(setShouldRefetchDocuments(undefined));
          props.handleDocumentSaved(document);
        } else if (props.handleCloseModal) {
          store.dispatch(setShouldRefetchDocuments(undefined));
          props.handleCloseModal();
        } else {
          router.push(`/tools/${selectedProjectId}/documents/${response.data.data?.id ?? response.data.data}`);
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'uploadingFile', value: false }));
      });
  };

  const handleExistingDocument = () => {
    if (typeof document !== 'undefined') {
      if (document.id) {
        methods.setValue('id', document.id);
      }
      if (document.name) {
        methods.setValue('name', document.name ?? '');
      }
      if (document.description) {
        methods.setValue('description', document.description ?? '');
      }
      if (document.files && document.files.length) {
        cameraProps.filterPhotosFromFiles(document.files, fileProps.setUploadedFiles);
      }
      if (document.categories && document.categories.length) {
        methods.setValue('local_document_types', document.categories.map(c => c.id));
      }
      if (document.related_tags && document.related_tags.length) {
        let tagIds = document.related_tags.map(t => t.id);
        if (document.categories && document.categories.length) {
          tagIds = tagIds.filter(t => !document.categories.some(c => c.id === t));
        }
        methods.setValue('local_tags', tagIds);
      }
      if (document.activity_codes && document.activity_codes.length) {
        methods.setValue('local_activity_codes', document.activity_codes.map(a => a.id));
      }
    }
  };

  const handleRemoveFile = (file: E2U.V1.Models.File) => {
    if (file.type === 'image') {
      cameraProps.removePhoto(file);
    } else {
      fileProps.removeFile(file);
    }
  };

  const handleCategoryTags = () => {
    if (categoryTags && categoryTags.length > 0 && (!localDocumentTypes || localDocumentTypes.length === 0)) {
      methods.setValue('local_document_types', categoryTags.map(t => t.id));
    }
  };

  useEffect(() => {
    handleExistingDocument();
    handleCategoryTags();
  }, []);

  useEffect(() => {
    handleExistingDocument();
  }, [document]);

  useEffect(() => {
    handleCategoryTags();
  }, [categoryTags]);

  useEffect(() => {
    readFiles((fileProps.filesToUpload ?? []).concat(cameraProps.photosToUpload ?? []));
  }, [cameraProps.photosToUpload, fileProps.filesToUpload, readFiles]);

  return (
    <section className={styles['file-form']}>
      {(isLoadingDocument || !selectedProjectId)
        ? <SkeletonTextThreeLines />
        : <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            {document && document.id && (
              <div className={styles['document-form-header']}>
                <BigUp.Title label={t('Edit document')} />
              </div>
            )}
            <IonGrid className={'ion-no-padding'}>
              <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-bottom'>
                <IonCol size='12'>
                  <BigUp.OutlinedInput
                    validation={{
                      required: false,
                      minLength: 3,
                      maxLength: 255,
                    }}
                    inputMode='text'
                    autoCapitalize='sentences'
                    customLabel={t('Name')}
                    placeholder={t('E.g. floor plan')}
                    register={'name'}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <BigUp.Label.Thick label={t('Activity codes')} />
                </IonCol>
              </IonRow>
              <IonRow className={'ion-margin-bottom'}>
                <IonCol>
                  <MultiSelector
                    title={t('Activity Codes')}
                    identifier='activity_codes'
                    triggerProps={{
                      icon: add,
                      label: t('Add'),
                      hideChevron: true,
                      chips: {
                        triggerPlacement: 'right',
                        type: 'individual'
                      }
                    }}
                    sourceUrl={{
                      url: `/api/v1/activity_categories`,
                      args: {
                        'with[]': 'activityCodes'
                      }
                    }}
                    callbacks={{
                      parseResponse: (response: {
                        code: string;
                        name: string
                      }[]) => {
                        return response.map((item: E2U.V1.Models.ActivityCategory) => ({
                          label: `${item.code_prefix} ${item.name}`,
                          value: item.id,
                          children: item.activity_codes?.map((child: E2U.V1.Models.ActivityCode) => ({
                            label: `${child.code} ${child.name}`,
                            value: child.id,
                          }))
                        }));
                      },
                      handleUserSelection: (items: string[]) => {
                        methods.setValue('local_activity_codes', items);
                      }
                    }}
                    excludeSelectedParentFromCallback={true}
                    defaultValue={localActivityCodes ?? []}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={'ion-margin-bottom'}>
                <IonCol>
                  <TextareaOutlined
                    itemProps={{ className: 'ion-no-padding', }}
                    inputMode='text'
                    autoCapitalize='sentences'
                    customLabel={t('Description (optional)')}
                    placeholder={t('Describe what the document contains')}
                    register={'description'}
                    rows={3}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <BigUp.Label.Thick label={t('Document category')} />
                </IonCol>
              </IonRow>
              <IonRow className={'ion-margin-bottom'}>
                <IonCol>
                  <MultiSelector
                    title={t('Document category')}
                    identifier='tag_categories'
                    triggerProps={{
                      icon: add,
                      label: (selectedItems) => {
                        if (selectedItems.length > 0) {
                          return selectedItems.map((item) => item.shortLabel ?? item.label).join(', ');
                        }
                        return t('Add');
                      },
                      hideChevron: true,
                      chips: {
                        showAsSelect: true,
                        triggerPlacement: 'right',
                        type: 'individual'
                      }
                    }}
                    sourceUrl={{
                      url: `/api/v1/tag_categories`,
                      args: {
                        'with[]': 'tags:not--is_system_record|||null--protected_at', // TODO: Refactor this
                        filters: JSON.stringify([
                          {
                            field: 'project_id',
                            value: selectedProjectId
                          },
                          {
                            field: 'is_document_type',
                            value: true
                          }
                        ])
                      }
                    }}
                    callbacks={{
                      parseResponse: (response: {
                        code: string;
                        name: string
                      }[]) => {
                        return response.flatMap((item: E2U.V1.Models.TagCategory) => (
                          (item.tags ?? []).map((child: E2U.V1.Models.Tag) => ({
                            label: child.name,
                            value: child.id,
                            icon: getDocumentTypeColor(child.color ?? 'grey')
                          }))
                        ));
                      },
                      handleUserSelection: (items: string[]) => {
                        methods.setValue('local_document_types', items);
                      }
                    }}
                    excludeSelectedParentFromCallback={true}
                    defaultValue={localDocumentTypes ?? []}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={'ion-margin-bottom'}>
                <IonCol>
                  <DragAndDrop
                    onFilesSelect={(files) => fileProps.handleFileSelection(files)}
                    allowCamera
                    cameraProps={cameraProps}
                  >
                    <div className={styles['file-upload-container']}>
                      <div className={styles['file-upload-icon']}>
                        <IonIcon
                          icon={attachmentsSVG}
                          className={styles['file-upload-icon-inner']}
                          color='primary'
                        />
                      </div>
                      <BigUp.Label.Regular className='ion-no-margin' label={fileUploadLabel} color={'medium'} />
                    </div>
                  </DragAndDrop>
                </IonCol>
              </IonRow>

              {((files && files.length > 0) || (photos && photos.length > 0)) && (
                <>
                  <IonRow className={'ion-justify-content-between ion-align-items-center'}>
                    <IonCol size={'auto'}>
                      <IonLabel className={'ion-no-margin'}>
                        <BigUp.Label.Thick label={t('Files')} />
                      </IonLabel>
                    </IonCol>
                    <IonCol size={'auto'}>
                      <BigUp.Buttons.ListToggle
                        toggle={fileListViewToggle}
                        handleClick={() => setFileListViewToggle(!fileListViewToggle)}
                        disable={false}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow className={'ion-margin-bottom'}>
                    <IonCol>
                      <BigUp.Preview.PreviewListWrapper toggle={fileListViewToggle}>
                        {fileProps.filesToUpload.concat(cameraProps.photosToUpload).map((file: E2U.V1.Models.File, index) => {
                          const listItemProps: FilePreviewProps = {
                            fileName: file.name,
                            fileType: file.type,
                            fileUrl: thumbnails[index],
                            onDelete: () => {
                              cameraProps.removePhotoFromPhotosToUpload(file);
                              fileProps.removeFileFromFilesToUpload(file);
                            },
                            result,
                          };
                          return (
                            fileListViewToggle
                              ? (
                                <BigUp.Preview.PreviewItem
                                  key={index}
                                  {...listItemProps}
                                />
                              )
                              : (
                                <BigUp.Preview.PreviewCard
                                  key={index}
                                  {...listItemProps}
                                />
                              )
                          );
                        })}
                        {files.concat(photos).map((upload, i) => {
                          const uploadProps: FilePreviewProps = {
                            fileName: upload.name,
                            fileType: upload.type,
                            fileUrl: fileUrls[upload.id as string],
                            previewLoading: loadingPreview,
                            onDelete: () => handleRemoveFile(upload)
                          };
                          return (
                            (upload.id) && (
                              fileListViewToggle
                                ? <BigUp.Preview.PreviewItem key={i} {...uploadProps} />
                                : <BigUp.Preview.PreviewCard key={i}{...uploadProps} />
                            )
                          );
                        })}
                      </BigUp.Preview.PreviewListWrapper>
                    </IonCol>
                  </IonRow>
                </>
              )}
              <IonRow>
                <IonCol>
                  <BigUp.Label.Thick label={t('Tags (optional)')} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <MultiSelector
                    title={t('Tags')}
                    identifier='tags'
                    triggerProps={{
                      icon: add,
                      label: t('Add'),
                      hideChevron: true,
                      chips: {
                        triggerPlacement: 'right',
                        type: 'individual'
                      }
                    }}
                    sourceUrl={{
                      url: `/api/v1/projects/${selectedProjectId}/tag_categories`,
                      args: {
                        'with[]': 'tags:not--is_system_record|||null--protected_at',
                        'sort_by[]': 'name',
                        direction: 'asc',
                        filters: JSON.stringify([
                          {
                            field: 'is_document_type',
                            value: false
                          }
                        ])
                      }
                    }}
                    callbacks={{
                      parseResponse: (response: {
                        code: string;
                        name: string
                      }[]) => {
                        return response.map((item: E2U.V1.Models.TagCategory[]) => ({
                          label: item.name,
                          value: item.id,
                          children: item.tags?.map((child: E2U.V1.Models.Tag) => ({
                            label: child.name,
                            value: child.id,
                          }))
                        }));
                      },
                      handleUserSelection: (items: string[]) => {
                        methods.setValue('local_tags', items);
                      }
                    }}
                    excludeSelectedParentFromCallback={true}
                    defaultValue={localTags ?? []}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid>
              <IonRow className='ion-justify-content-center ion-margin-top ion-padding-horizontal'>
                <IonCol size={'8'}>
                  <BigUp.Buttons.Primary
                    expand='full'
                    title={(document && document.id) ? t('Save') : i18n.t('Create')}
                    type={'submit'}
                    disabled={submitButtonDisabled}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </FormProvider>
      }
    </section >
  );
};

export default DocumentForm;
