import { IonCol, IonGrid, IonRow, useIonRouter, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import LatestFileList from './LatestFileList';
import { networking } from '../../../api/networking';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import { MultiSelectorGroup } from '../../../components/UI/MultiSelector';
import type {
  MultiSelectorGroupValuesProps,
  MultiSelectorItemProps
} from '../../../components/UI/MultiSelector/interfaces';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../../../hooks';
import type { SourceUrlProps } from '../../../interfaces/SourceUrlProps';
import { setSelectedCategory } from '../../../reducers/document';
import { setShouldRefetchDocuments } from '../../../reducers/file';
import { setForceReloadDocument } from '../../../reducers/files';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import FilePreviewColumn from '../../Document/Columns/FilePreviewColumn';
import { FILTER_TYPES } from '../../Document/DocumentsListTable';
import { getDocumentRoute } from '../../Document/DocumentsListTable/documentSwitch';
import { DocumentActivityCodeFilter, DocumentExtensionCodeFilter } from '../../Document/Filters';

const DocumentCategoryPage: React.FC = () => {
  const isLoadingType = useAppSelector(state => state.loading.isLoading.documentType);
  const { category_id } = useParams<{ uuid: string, category_id: string }>();
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const router = useIonRouter();
  const lastReloadTs = useAppSelector(state => state.file.lastReloadTs);
  const parsedCategoryId = useMemo(() => category_id === 'uncategorized' ? 'none' : category_id, [category_id]);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const category = useAppSelector(state => state.document.selectedCategory);
  const forceReloadDocument = useAppSelector(state => state.fileSelection.forceReloadDocument);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<MultiSelectorGroupValuesProps>({});
  const [previousDataSourceUrl, setPreviousDataSourceUrl] = useState<SourceUrlProps | undefined>(undefined);

  const defaultUrl = useMemo(() => {
    if (!selectedProjectId) {
      return undefined;
    }
    return {
      url: `/api/v1/projects/${selectedProjectId}/documents`,
      args: {
        per_page: 9999,
        fields: 'id,name,files_count,files,resource_type,resource_id',
        'with[]': 'files',
        filters: JSON.stringify([{
          field: 'document_type',
          value: parsedCategoryId
        }])
      },
    };
  }, [selectedProjectId]);

  const parseBaseFilterChange = (
    type: 'documents_filters' | 'file_filters',
    existingFilters: { field: string, value: any }[],
    filterKey: string,
    filter: MultiSelectorItemProps[]
  ) => {
    const existingFilterParentIndex = existingFilters.findIndex(
      parent => parent.field === type
    );
    if (existingFilterParentIndex === -1) {
      existingFilters.push({
        field: type,
        value: {
          [filterKey]: filter.join(',')
        }
      });
      return existingFilters;
    }
    existingFilters[existingFilterParentIndex].value = {
      ...(existingFilters[existingFilterParentIndex]?.value ?? {}),
      [filterKey]: filter.join(',')
    };
    return existingFilters;
  };

  const parseDocumentFilter = (
    existingFilters: { field: string, value: any }[],
    filterKey: string,
    filter: any|any[]
  ) => {
    return parseBaseFilterChange(
      'documents_filters',
      existingFilters,
      filterKey,
      Array.isArray(filter) ? filter : [filter]
    );
  };

  const parseFileFilter = (
    existingFilters: { field: string, value: any }[],
    filterKey: string,
    filter: any|any[]
  ) => {
    const fileVal = parseBaseFilterChange(
      'file_filters',
      existingFilters,
      filterKey,
      Array.isArray(filter) ? filter : [filter]
    );
    return fileVal;
  };

  const resetSearchFilters = (
    existingFilters: { field: string, value: any }[]|string
  ) => {
    const parsedFilters: { field: string, value: any }[] = typeof existingFilters === 'string' ? JSON.parse(existingFilters) : existingFilters;
    return parsedFilters.filter(
      filter => {
        return (
          searchQuery.length ||
          parsedFilters.some(f => f.field === 'document_filters')
        ) && filter.field !== 'document_type';
      }
    ).map(
      filter => {
        if (filter.field === 'documents_filters' || filter.field === 'file_filters') {
          return {
            ...filter,
            value: {}
          };
        }
        return filter;
      }
    );
  };

  const parseFilterArgs = (args: any, filterKeys: string[], filters: MultiSelectorGroupValuesProps) => {
    if (typeof args.filters === 'string') {
      args.filters = JSON.parse(args.filters);
    }
    args.filters = resetSearchFilters(args.filters ?? []);
    filterKeys.forEach(
      filterKey => {
        args.filters = FILTER_TYPES[filterKey] === 'documents'
          ? parseDocumentFilter(args.filters, filterKey, filters[filterKey])
          : parseFileFilter(args.filters, filterKey, filters[filterKey]);
      }
    );
    parseDocumentFilter(args.filters, 'categories', parsedCategoryId);
    return args;
  };

  const dataSourceUrl = useMemo(() => {
    if (!selectedProjectId) {
      return undefined;
    }

    let args: {[argKey: string]: any} = { search: searchQuery };
    if (
      previousDataSourceUrl !== undefined &&
      typeof previousDataSourceUrl === 'object' &&
      typeof previousDataSourceUrl.args !== 'undefined'
    ) {
      args = { ...previousDataSourceUrl.args, ...args };
    }

    const filters = activeFilters ?? {};
    const filterKeys = Object.keys(filters);
    args = parseFilterArgs(args, filterKeys, filters);

    if (args.filters && typeof args.filters !== 'string') {
      args.filters = JSON.stringify(args.filters);
    }

    return (searchQuery.length || Object.keys(activeFilters).length)
      ? {
        args: {
          ...args,
          fields: 'id,name,type,filetype,export_url',
          'with[]': []
        },
        url: `/api/v1/projects/${selectedProjectId}/documents/files`,
      }
      : defaultUrl;
  }, [searchQuery, previousDataSourceUrl, activeFilters, selectedProjectId, defaultUrl]);

  const fetchCategory = () => {
    if (!category_id || parsedCategoryId === 'none') {
      return;
    }
    store.dispatch(setIsLoading({ name: 'documentType', value: true }));
    networking.get(`/api/v1/document_types/${category_id}?appends[]=category_tags&per_page=9999`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Type>) => {
        store.dispatch(setSelectedCategory(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        toasters.error(t('Document category not found'));
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'documentType', value: false }));
      });
  };

  const handleSearch = (query: string, currentUrl: SourceUrlProps | string) => {
    if (!query.length) {
      if (!searchQuery.length) {
        return;
      }

      setSearchQuery(query);
    } else {
      setSearchQuery(query);
    }

    if (typeof currentUrl === 'string') {
      setPreviousDataSourceUrl(undefined);
      return;
    }
    setPreviousDataSourceUrl(currentUrl);
  };

  useIonViewDidEnter(() => {
    fetchCategory();
  });

  useEffect(() => {
    if (forceReloadDocument) {
      fetchCategory();
      store.dispatch(setForceReloadDocument(false));
    }
  }, [forceReloadDocument]);

  useIonViewWillLeave(() => {
    setSearchQuery('');
    setActiveFilters({});
    store.dispatch(setSelectedCategory(undefined));
  });

  useEffect(() => {
    store.dispatch(setShouldRefetchDocuments(undefined));
  }, [selectedProjectId]);

  return (
    <SideMenuV2Layout title={category?.name ?? t('Uncategorized')} showDocumentActionsButton={true} paddedPage={false}>
      {
        (isLoadingType || !selectedProjectId)
          ? (
            <SkeletonTextThreeLines />
          )
          : (
            <div className='ion-padding'>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    {isDesktop && <IonGrid>
                      <IonRow>
                        <IonCol>
                          <BigUp.Title label={category?.name} />
                        </IonCol>
                      </IonRow>
                    </IonGrid>}
                    {dataSourceUrl && <BigUp.Table
                      timestamp={lastReloadTs}
                      sourceUrl={dataSourceUrl}
                      columns={
                        [{
                          key: 'name',
                          label: t('Name'),
                          flex: true,
                          alignment: 'left',
                          sizes: {
                            xs: '7',
                            sm: '7',
                            md: '8',
                            lg: '9',
                            xl: '9'
                          },
                          sortable: true,
                          body: (row: E2U.V1.Models.Type | E2U.V1.Models.File) => {
                            return 'filetype' in row
                              ? <FilePreviewColumn attributes={row} />
                              : <span>{row.name}</span>;
                          },
                        }, {
                          key: 'files_count',
                          flex: true,
                          label: t('Count'),
                          alignment: 'right',
                          sizes: {
                            xs: '5',
                            sm: '5',
                            md: '4',
                            lg: '3',
                            xl: '3'
                          },
                        }]
                      }
                      filtersCustom={
                        <MultiSelectorGroup callbacks={{
                          onSelectionChange: (selection: MultiSelectorGroupValuesProps) => {
                            setPreviousDataSourceUrl(defaultUrl);

                            const filters = Object.entries(selection).reduce((acc, [key, value]) => {
                              return {
                                ...acc,
                                [key]: value.join(','),
                              };
                            }, {});

                            setActiveFilters(filters);
                          }
                        }}>
                          <DocumentActivityCodeFilter sourceUrl={{
                            url: `/api/v1/projects/${selectedProjectId}/documents/filters/activity_codes`,
                            args: {
                              'others[categories]': parsedCategoryId,
                              'others[search]': searchQuery,
                            },
                          }} />
                          <DocumentExtensionCodeFilter sourceUrl={{
                            url: `/api/v1/projects/${selectedProjectId}/documents/filters/extensions`,
                            args: {
                              'others[categories]': parsedCategoryId,
                              'others[search]': searchQuery,
                            },
                          }} />
                        </MultiSelectorGroup>
                      }
                      collapsible={{
                        key: 'files',
                        rowIsEnabled: (row: E2U.V1.Models.Document & { files_count?: number }) => {
                          return !!row.files && row.files.length > 0;
                        },
                        componentKey: 'files',
                        component: LatestFileList,
                      }}
                      callbacks={{
                        onSearch: handleSearch,
                        onRowClick: (row: E2U.V1.Models.Document | E2U.V1.Models.File) => {
                          if ('filetype' in row) {
                            router.push(`/tools/${selectedProjectId}/file/${row.id}`);
                          } else {
                            router.push(getDocumentRoute(row as E2U.V1.Models.Document, selectedProjectId));
                          }
                        },
                        onRenderComplete: (rows: (E2U.V1.Models.Document & { files_count?: number })[], setRows) => {
                          setRows(rows.map((row) => {
                            return { ...row, files_count: t('{count} files', { count: row.files_count ?? 0 }) };
                          }));
                        }
                      }}
                      reducers={{
                        files_count: (value?: string) => value ?? t('Show file'),
                      }}
                    />}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )
      }
    </SideMenuV2Layout>
  );
};

export default DocumentCategoryPage;
