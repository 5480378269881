import { IonCol, IonGrid, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { checkmarkCircleSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './ProductionBudget.module.scss';
import RegenerationForm from './RegenerationForm';
import { networking } from '../../../../api/networking';
import AlarmModalContent from '../../../../components/Alarms/AlarmModalContent';
import SkeletonItem from '../../../../components/SkeletonComponents/SkeletonItem';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import { useAppSelector } from '../../../../hooks';
import useModal from '../../../../hooks/useModal';
import { setIsLoading } from '../../../../reducers/loading';
import { setSelectedPrecalculation } from '../../../../reducers/precalculations';
import { setProductionBudget } from '../../../../reducers/production-budget';
import { softUnlock } from '../../../../reducers/subpanelLock';
import store from '../../../../store';
import formatNumber from '../../../../tools/formatNumber';
import modalStyle from '../../../Onboarding/Components/containers/styles/ModalContainers.module.scss';
import ActivityCodeColumn from '../Precalculations/Columns/ActivityCodeColumn';

interface Lock<T> {
  isLocked: boolean | T;
  fetchProject: () => void;
}

const ProductionBudget: React.FC<Lock<boolean>> = (props: Lock<boolean>) => {
  const { t } = useTranslation();
  const [timestamp, setTimestamp] = useState(new Date());
  const [productionBudgetRows, setProductionBudgetRows] = useState<E2U.V1.Models.ProductionBudgetRow[]>([]);
  const [projectPrecalculations, setProjectPrecalculations] = useState<E2U.V1.Models.Precalculation[]>([]);
  const [userPrecalculations, setUserPrecalculations] = useState<E2U.V1.Models.Precalculation[]>([]);
  const { closeModal, isModalOpen, openModal } = useModal();
  const { handleSubmit } = useForm<FieldValues>({ mode: 'onChange', reValidateMode: 'onChange' });

  const closeAlarmingModal = () => {
    closeModal();
    store.dispatch(setSelectedPrecalculation(undefined));
  };

  const generateMethods = useForm<{
    precalculation_id: string;
  }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      precalculation_id: undefined,
    }
  });

  const productionBudget: E2U.V1.Models.ProductionBudget | undefined | any =
    useAppSelector((state) => state.productionBudget.productionBudget);
  const isLoadingProductionBudget = useAppSelector((state) => state.loading.isLoading.productionBudget);
  const isLoadingUserPrecalculations = useAppSelector((state) => state.loading.isLoading.userPrecalculations);
  const isLoadingProjectPrecalculations = useAppSelector((state) => state.loading.isLoading.projectPrecalculations);
  const isLocked = useAppSelector(state => state.lockSubpanels.isLocked.productionBudgets);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const currentUser = useAppSelector(state => state.authentication.user);

  const fetchProductionBudget = async (): Promise<any> => {
    if (!selectedProject?.id) return;
    store.dispatch(setIsLoading({ name: 'productionBudget', value: true }));
    try {
      const response = await toasters.promise(
        networking.get(`/api/v1/projects/${selectedProject?.id}/production_budget?with=lockedBy`),
        { error: t("Couldn't load production budget rows") }
      );
      store.dispatch(setProductionBudget(response.data.data.records[0]));
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      store.dispatch(setIsLoading({ name: 'productionBudget', value: false }));
    }
  };

  const fetchRows = () => {
    networking.get(`/api/v1/production_budgets/${productionBudget.id}/rows`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.ProductionBudgetRow>>) => {
        setProductionBudgetRows(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const toastersLockProductionBudget = {
    pending: t('Locking production budget...'),
    success: t('Production budget is locked'),
    error: t("Couldn't lock production budget")
  };
  const selectedPrecalculation = useAppSelector(state => state.precalculations.selectedPrecalculation);

  const lockProductionBudget: SubmitHandler<FieldValues> = () => {
    toasters
      .promise(networking.post(`/api/v1/production_budgets/${productionBudget?.id}/lock`, {
        lock_date: new Date().toISOString(),
        precalculation_id: selectedPrecalculation
      }), { ...toastersLockProductionBudget, })
      .then(() => {
        setTimestamp(new Date());
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        onLockDate();
      });
  };

  const onLockDate = () => {
    closeModal();
    store.dispatch(softUnlock(false));
    store.dispatch(setSelectedPrecalculation(undefined));
  };

  const fetchProjectPrecalculations = () => {
    setProjectPrecalculations([]);
    store.dispatch(setIsLoading({ name: 'projectPrecalculations', value: true }));
    networking.get(`/api/v1/projects/${selectedProject?.id}/precalculations`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Precalculation>>) => {
        setProjectPrecalculations(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'projectPrecalculations', value: false }));
      });
  };

  const fetchUserPrecalculations = () => {
    setUserPrecalculations([]);
    store.dispatch(setIsLoading({ name: 'userPrecalculations', value: true }));
    networking.get(`/api/v1/users/${currentUser?.id}/precalculations?per_page=9999`)
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Precalculation>>) => {
          setUserPrecalculations(response.data.data.records);
        })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'userPrecalculations', value: false }));
      });
  };

  const handleGenerateBudget: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({ name: 'generatingProductionBudget', value: true }));
    networking.post(`/api/v1/production_budgets/${productionBudget?.id}/${data.precalculation_id}/regenerate`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.ProductionBudget>) => {
        setProductionBudget(response.data.data);
        generateMethods.reset();
        fetchProductionBudget();
        setTimestamp(new Date());
        fetchRows();
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'generatingProductionBudget', value: false }));
      });
  };

  useEffect(() => {
    fetchProjectPrecalculations();
    fetchUserPrecalculations();
    fetchProductionBudget();
  }, [selectedProject?.id]);

  useEffect(() => {
    if (productionBudget?.id) {
      fetchRows();
    }
  }, [selectedProject?.id, productionBudget?.id, productionBudget?.locked_by]);

  return (
    <React.Fragment>
      <div className={'ion-padding'}>
        <div className={styles['production-budget-page-container']}>
          <div className={styles['production-budget-container']} slot="content">
            <div className={styles['production-row-container']} style={{ width: '100%' }}>
              <IonGrid>
                <IonRow className='ion-margin-bottom  ion-align-items-center ion-justify-content-start'>
                  <IonCol size='auto' className='ion-padding-end ion-margin-end'>
                    <BigUp.Title label={t('Production budget')} />
                  </IonCol>
                  {productionBudget?.locked_by && (
                    <>
                      <IonCol sizeXs='12' sizeSm='8' sizeMd='auto' className={styles['indicator-column']}>
                        <BigUp.Label.Indicator
                          indicator={{
                            color: 'var(--ion-color-success)',
                            shape: 'circle',
                          }}
                          label={{
                            gap: '5px',
                            start: { label: t('Locked by') },
                            end: { label: `${productionBudget?.locked_by?.first_name} ${productionBudget?.locked_by?.last_name}` },
                          }}
                        />
                      </IonCol>
                      <IonCol sizeXs='12' sizeSm='8' sizeMd='auto' className={styles['indicator-column']}>
                        <BigUp.Label.Indicator
                          indicator={{
                            color: 'var(--ion-color-danger)',
                            shape: 'circle',
                          }}
                          label={{
                            gap: '5px',
                            start: { label: t('Locked at') },
                            end: { label: productionBudget?.lock_date },
                          }}
                        />
                      </IonCol>
                    </>
                  )}
                </IonRow>
                {isLoadingProductionBudget || isLoadingProjectPrecalculations || isLoadingUserPrecalculations
                  ? <SkeletonItem amount={5} sizes={{
                    sizeLg: '12',
                    sizeMd: '12',
                    sizeSm: '12',
                    sizeXs: '12'
                  }} />
                  : (
                    <>
                      <IonRow className='ion-justify-content-end ion-align-items-center ion-margin-vertical'>
                        <FormProvider {...generateMethods}>
                          <RegenerationForm
                            generateBudget={() => {
                              handleGenerateBudget({
                                precalculation_id: selectedPrecalculation
                              });
                            }}
                            isLocked={isLocked}
                            userPrecalculations={userPrecalculations}
                            projectPrecalculations={projectPrecalculations}
                          />
                        </FormProvider>
                        {productionBudgetRows && productionBudgetRows.length === 0
                          ? <></>
                          : <BigUp.Buttons.Secondary
                            size='small'
                            color='danger'
                            title={t('Lock budget')}
                            onClick={openModal}
                          />
                        }

                      </IonRow>

                      {productionBudgetRows && productionBudgetRows.length === 0
                        ? (
                          <IonGrid>
                            <IonRow className='ion-align-items-center ion-justify-content-center'>
                              <IonCol size={'12'} className='ion-text-center'>
                                <BigUp.Label.Thick color='dark' label={t('Generate the production budget to get started.')} />
                              </IonCol>
                            </IonRow>
                            <IonRow className='ion-align-items-center ion-justify-content-center'>
                              <IonCol sizeSm={'8'} className='ion-text-center'>
                                <BigUp.Label.Regular
                                  label={t('The production budget has not been generated. Select a precalculation below and press "Generate" to get started.')}
                                  className='ion-no-margin ion-text-center'
                                />
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        )
                        : <BigUp.Table
                          timestamp={timestamp}
                          sourceUrl={`/api/v1/production_budgets/${productionBudget && productionBudget.id}/rows`}
                          columns={
                            [{
                              key: 'stamped_activity_code',
                              label: t('Title'),
                              alignment: 'left',
                              sizes: {
                                xs: '6',
                                sm: '4',
                                md: '4',
                                lg: '4',
                                xl: '3'
                              },
                              sortable: true,
                              default_sort: true,
                              default_sort_direction: 'asc',
                              body: <ActivityCodeColumn
                                value=''
                                attributes={{} as E2U.V1.Models.PrecalculationRow} />,
                            },
                            {
                              key: 'quantity',
                              label: 'Quantity',
                              alignment: 'left',
                              sortable: true,
                              sizes: {
                                xs: '4',
                                sm: '2',
                                md: '3',
                                lg: '2',
                                xl: '2'
                              }
                            },
                            {
                              key: 'cost_per_unit',
                              label: 'Cost/unit',
                              sortable: true,
                              alignment: 'left',
                              sizes: {
                                xs: '4',
                                sm: '2',
                                md: '3',
                                lg: '2',
                                xl: '2'
                              }
                            },
                            {
                              key: 'unit',
                              label: 'Unit',
                              sortable: true,
                              sizes: {
                                xs: '4',
                                sm: '2',
                                md: '3',
                                lg: '2',
                                xl: '2'
                              }
                            },
                            {
                              key: 'total',
                              alignment: 'right',
                              label: 'Total',
                              sortable: true,

                              sizes: {
                                xs: '4',
                                sm: '4',
                                md: '4',
                                lg: '4',
                                xl: '2'
                              }
                            },]
                          }
                          reducers={{
                            quantity: (value: number) => formatNumber(value) || '0',
                            cost_per_unit: (value: number) => formatNumber(value) || '0',
                            total: (value: number) => formatNumber(value) || '0',
                          }}
                          filters={[
                            {
                              label: t('Search'),
                              callback: () => console.log('filter clicked!')
                            }
                          ]}
                        />
                      }
                    </>
                  )}
              </IonGrid>
            </div>
          </div>

          <IonModal isOpen={isModalOpen} onIonModalDidDismiss={closeAlarmingModal} className={modalStyle['app-default-modal']}>
            <form onSubmit={handleSubmit(lockProductionBudget)} className={styles.projectLockForm}>
              {selectedProject?.locked &&
                <AlarmModalContent
                  modal={{ modalDismiss: closeAlarmingModal }}
                  colour={{
                    backgroundColour: 'var(--ion-color-danger)',
                    textColours: 'var(--ion-color-dark)'
                  }}
                  texts={{
                    alertTitle: t('Are you sure you want to re-lock the production budget?'),
                    alertSubtitle: `${selectedProject?.name}`,
                    contentText:
                      <IonList>
                        <IonListHeader>
                          <IonLabel>{t('Locking again will do:')}</IonLabel>
                        </IonListHeader>
                        <IonItem>
                          <IonLabel>{t('Your existing prognosis will be removed.')}</IonLabel>
                        </IonItem>
                      </IonList>
                  }}
                  buttonLeft={{
                    leftTitle: t('Cancel'),
                    leftIonColour: 'medium',
                    leftOnClick: closeAlarmingModal
                  }}
                  buttonRight={{
                    shouldSubmit: true,
                    rightTitle: t('Lock'),
                    rightIonColour: 'none',
                  }}
                />
              }
              {!selectedProject?.locked &&
                <AlarmModalContent
                  modal={{ modalDismiss: closeAlarmingModal }}
                  alert={{ alertIcon: checkmarkCircleSharp }}
                  colour={{
                    backgroundColour: 'var(--ion-color-success)',
                    textColours: 'var(--ion-color-light)',
                    buttonRightColour: 'var(--ion-color-light)'
                  }}
                  texts={{
                    alertTitle: t('Are you sure you want to lock the production budget?'),
                    alertSubtitle: `${selectedProject?.name}`,
                    contentText:
                      <IonList>
                        <IonListHeader>
                          <IonLabel>{t('Locking the budget will:')}</IonLabel>
                        </IonListHeader>
                        <IonItem>
                          <IonLabel>{t('A prognosis will be generated based on the budget.')}</IonLabel>
                        </IonItem>
                      </IonList>
                  }}
                  buttonLeft={{
                    leftTitle: t('Cancel'),
                    leftIonColour: 'none',
                    leftOnClick: closeModal
                  }}
                  buttonRight={{
                    shouldSubmit: true,
                    rightTitle: t('Unlock'),
                    rightIonColour: 'success',
                  }}
                />
              }
            </form>
          </IonModal>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ProductionBudget;
