import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import AccessList from '../../../components/AccessManagement/AccessList';
import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import { add, cloudUpload, cloudUploadOutline } from 'ionicons/icons';

interface AccessManagementPageProps {
  type: 'clients' | 'projects';
}

interface AclListEntity {
  id: string;
  name: string;
  count: number;
  email?: string;
}

export interface FoundEntity extends E2U.V1.Objects.AclFoundEntity {
  count: number;
}

const AccessManagementPage: React.FC<AccessManagementPageProps> = ({ type }) => {
  const { t } = useTranslation();
  const [accessRows, setAccessRows] = useState<E2U.V1.Objects.AclFoundEntity[]>([]);
  const { client_id } = useParams<{ client_id: string }>();
  const selectedProject = useAppSelector(state => state.project.selectedProject);

  const fetchAssignedAccess = () => {
    if ((type === 'clients' && !client_id) || (type === 'projects' && !selectedProject?.id)) return;
    const id = type === 'clients' ? client_id : selectedProject?.id;
    networking.get(`/api/v1/${type}/${id}/acl/list`)
      .then((res: E2U.V1.Response.Success<{
        users: AclListEntity[],
        teams: AclListEntity[]
      }>) => {
        setAccessRows([
          ...res.data.data.users.map((user: AclListEntity) => ({
            id: user.id,
            name: user.name,
            email: user.email,
            count: user.count,
            type: 'user'
          }) as FoundEntity),
          ...res.data.data.teams.map((team: AclListEntity) => ({
            id: team.id,
            name: team.name,
            count: team.count,
            type: 'team',
            email: ''
          }) as FoundEntity)
        ]);
      });
  };

  useEffect(() => {
    fetchAssignedAccess();
  }, []);

  useEffect(() => {
    fetchAssignedAccess();
  }, [client_id, selectedProject]);

  return (
    <div className={'ion-padding'}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <BigUp.Title label={t('Access management')} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className='ion-no-padding'>
            <AccessList
              rows={accessRows as FoundEntity[]}
              entity={type}
              id={type === 'clients' ? client_id : selectedProject?.id ?? ''}
              handleAccessGranted={() => fetchAssignedAccess()}
              handleAccessRevoked={() => fetchAssignedAccess()}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <BigUp.Label.Thick label={t('Mass Import')} />
            <p className={'ion-no-margin ion-margin-bottom'}>
              {t('Give access to one or many users and teams at the same time.')}
            </p>
            <BigUp.Buttons.Secondary
              size='default'
              icon={{ icon: cloudUploadOutline }}
              title={t('Start import')}
              routerLink={type === 'clients'
                ? `/clients/${client_id}/access/add/import`
                : `/tools/${selectedProject?.id}/settings/access/add/import`
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};
export default AccessManagementPage;
